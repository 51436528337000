define('throttle', function() {
  'use strict';
  // Borrowed/adapted from underscore.js
  // Returns a function, that, when invoked, will only be triggered at most once
  // during a given window of time. Normally, the throttled function will run
  // as much as it can, without ever going more than once per `wait` duration;
  // but if you'd like to disable the execution on the leading edge, pass
  // `{leading: false}`. To disable execution on the trailing edge, ditto.

  var Throttle = function(func, wait, options) {
    var self = this;
    self.props = {
      args: null,
      context: null,
      func: func,
      options: options || {},
      previous: 0,
      result: null,
      timeout: null,
      wait: wait,
    };

    self.later = function() {
      var props = self.props;
      props.previous = props.options.leading === false ? 0 : self.getDateNow();
      props.timeout = null;
      props.result = props.func.apply(props.context, props.args);
      self.setContextAndArgsToNull(props.timeout);
    };

    self.run = function() {
      var now = self.getDateNow();
      var props = self.props;
      props.context = this;
      props.args = arguments;

      if (!props.previous && props.options.leading === false) {
        props.previous = now;
      }

      var remaining = self.getRemainingTime(props.wait, now, props.previous);

      if (self.hasExpired(remaining, props.wait)) {
        if (props.timeout) {
          clearTimeout(props.timeout);
          props.timeout = null;
        }
        props.previous = now;
        props.result = func.apply(props.context, props.args);
        self.setContextAndArgsToNull(props.timeout);
      } else if (!props.timeout && props.options.trailing !== false) {
        props.timeout = setTimeout(self.later, remaining);
      }
      return props.result;
    };

    self.getDateNow = function() {
      return new Date().getTime();
    };

    self.getRemainingTime = function(wait, now, previous) {
      return wait - (now - previous);
    };

    self.hasExpired = function(remaining, wait) {
      return remaining <= 0 || remaining > wait;
    };

    self.setContextAndArgsToNull = function(timeout) {
      if (!timeout) {
        self.props.context = self.props.args = null;
      }
    };
  };

  return Throttle;
});
